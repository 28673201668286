import React from "react"
import axios from "axios"
import "./FormContact.css"

const intialState = {
  fullname: "",
  email: "",
  business: "",
  message: "",
  fullnameError: "",
  emailError: "",
  messageError: "",
  sending: false,
  textButton: "Enviar",
}

export default class ContactPage extends React.Component {
  state = intialState

  change = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  validate = () => {
    let fullnameError = ""
    let emailError = ""
    let messageError = ""

    if (!this.state.fullname) {
      fullnameError = "Es necesario ingresar un nombre"
    }

    if (!this.state.message) {
      messageError = "Es necesario ingresar un mensaje para poder contactarnos"
    }

    if (!this.state.email.includes("@")) {
      emailError = "Verifique si su email es correcto"
    }

    if (emailError || fullnameError || messageError) {
      this.setState({ emailError, fullnameError, messageError })
      return false
    }

    return true
  }

  onSubmit = e => {
    this.setState({ sending: true, textButton: "Enviando..." })
    e.preventDefault()
    try {
      this.props.onSubmit()
      const isValid = this.validate()

      console.log(this.state)

      //url:"http://localhost:3002/send",
      //url:"https://verpar.org.pe:3002/send",
      //url:"https://friendly-volhard-995818.netlify.com:3002/send",

      if (isValid) {
        axios({
          method: "POST",
          url: "https://thawing-inlet-64250.herokuapp.com/send",
          data: {
            name: this.state.fullname,
            email: this.state.email,
            business: this.state.business,
            message: this.state.message,
          },
        })
          .then(response => {
            if (response.data.status === "success") {
              alert("El mensaje fue enviado exitosamente.")
              this.setState(intialState)
            } else if (response.data.status === "fail") {
              alert("El mensaje no se envio. Intentelo más tarde.")
            }
          })
          .catch(function(error) {
            console.log(error)
            alert("El mensaje no se envio. Intentelo más tarde.")
          })

        this.setState({ sending: false, textButton: "Enviar" })
      }
    } catch (error) {
      alert("El mensaje no se envio. Intentelo más tarde.")
      this.setState({ sending: false, textButton: "Enviar" })
    }
  }

  render() {
    return (
      <form>
        <div className="ContactForm">
          <h4 className="TitleContactForm">Envianos un mensaje</h4>
          <div className="ItemForm">
            <h4 className="TitleForm">Nombre Completo *</h4>
            <input
              className="InputForm"
              name="fullname"
              placeholder="Ingresa tú nombre completo"
              value={this.state.fullname}
              onChange={e => this.change(e)}
            />
            {this.state.fullnameError ? (
              <div className="ShowError">{this.state.fullnameError}</div>
            ) : null}
          </div>
          <div className="ItemForm">
            <h4 className="TitleForm">Email *</h4>
            <input
              className="InputForm"
              name="email"
              placeholder="Ingresa tú email"
              value={this.state.email}
              onChange={e => this.change(e)}
            />
            {this.state.emailError ? (
              <div className="ShowError">{this.state.emailError}</div>
            ) : null}
          </div>
          <div className="ItemForm">
            <h4 className="TitleForm">Empresa</h4>
            <input
              className="InputForm"
              name="business"
              placeholder="Ingresa tu empresa"
              value={this.state.business}
              onChange={e => this.change(e)}
            />
          </div>
          <div className="ItemForm">
            <h4 className="TitleForm">Mensaje *</h4>
            <textarea
              className="TextAreaForm"
              name="message"
              placeholder="Ingresa un mensaje para que podamos contactarnos contigo"
              value={this.state.message}
              onChange={e => this.change(e)}
            />
            {this.state.messageError ? (
              <div className="ShowError">{this.state.messageError}</div>
            ) : null}
          </div>
          <div className="SubmitSection">
            <button
              className="ButtonItem"
              disabled={this.state.sending}
              onClick={e => this.onSubmit(e)}
            >
              {this.state.textButton}
            </button>
          </div>
        </div>
      </form>
    )
  }
}
