import React, { Component } from "react"
import FormContact from "../components/FormContact"
import Layout from "../components/layout"
import Seo from "../components/seo"
// images 
import logo from '../images/logoverpar.svg'

class contact extends Component {
  state = {
    fields: {},
  }

  onSubmit = fields => {
    this.setState({ fields })
  }

  render() {
    return (
      <Layout>
        <Seo title="Contact our team" />
        <div className="HeroPage">
          <h1>Contactenos</h1>
          <p>
            Cualquier dudas que tengas llena el formulario y estaremos en
            contacto contigo.
          </p>
        </div>
        <div className="ContactSection">
          <div className="FormSection">
            <FormContact onSubmit={fields => this.onSubmit(fields)} />
          </div>
          <div className="ContactInfo">
            <div className="HeadInfo">
              <img
                alt=""
                className="HeadImage"
                src={logo}
              />
              <h2 className="HeadTitle">VERPAR</h2>
            </div>
            <div className="ContactDescription">
              <h2>Información</h2>
              <div className="ContactData">
                <h4>Dirección</h4>
                <p className="InfoData">
                  Av. Paseo de la Republica 6190, Dpto 603
                </p>
                <br />
                <h4>Teléfono</h4>
                <p className="InfoData">+51 912 302 450</p>
                <br />
                <h4>Email</h4>
                <a href="mailto:contact@verpar.org.pe">
                  contacto@verpar.org.pe
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default contact
